import { makeStyles } from "@material-ui/core/styles"
import BaseBackdrop from "@material-ui/core/Backdrop"
import { useDispatch, useSelector } from "react-redux"
import { useEffect, useRef, useState } from "react"
import { setAlert } from "@/store/slices/alertSlice"
import { AlertMessageEnum, AlertTypeEnum } from "@/consts/alert-messages"
import { useRouter } from "next/router"
import dynamic from "next/dynamic"
import loader from "public/animations/loader-white.json"

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}))

const Lottie = dynamic(() => import('lottie-react'), { ssr: false })

function Backdrop() {
  const router = useRouter()
  const dispatch = useDispatch()
  const classes = useStyles()
  const { isBackdropOpen } = useSelector((state) => state.ui)

  const timeoutRef = useRef()
  const [hasSiteError, setHasSiteError] = useState(false)

  useEffect(() => {
    if (isBackdropOpen && !document.hidden) {
      timeoutRef.current = setTimeout(() => {
        setHasSiteError(true)

        dispatch(
          setAlert({
            isOpen: true,
            title: AlertMessageEnum.errors.generic.title,
            type: AlertTypeEnum.error,
            message: AlertMessageEnum.errors.generic.text,
          })
        )
      }, 15000)
    }

    return () => clearTimeout(timeoutRef.current)
  }, [dispatch, isBackdropOpen])

  return (
    <BaseBackdrop
      className={classes.backdrop}
      open={isBackdropOpen}
      onClick={() => {
        hasSiteError && router.reload()
      }}
    >
      <Lottie animationData={loader} style={{ height: 150, width: 150 }} />
    </BaseBackdrop>
  )
}

export default Backdrop
