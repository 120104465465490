import useSWR from "swr"
import { getCustomFieldsByLocationId } from "@/api/nextme/custom-fields"
import { refreshIntervals } from "@/consts/swr"
import useSelectedLocation from "../api-selected-hooks/useSelectedLocation"

export default function useCustomFieldsByLocationId() {
  const { locationId } = useSelectedLocation()

  const {
    data: customFields,
    isValidating,
    mutate,
    error,
  } = useSWR(
    `nextme/customFields-locationId-${locationId}`,
    () => getCustomFieldsByLocationId(locationId),
    {
      dedupingInterval: 10000, // 10s
      refreshInterval: refreshIntervals.verySlow,
      fallbackData: [],
      //   revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  )

  return {
    error,
    isValidating,
    mutate,
    customFields,
  }
}
