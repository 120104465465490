import { twConfig } from "@/styles/tailwind-utils"
import { useDesktopUpQuery } from "@/utils/responsive-queries"
import { IconButton, IconButtonProps } from "@mui/material"
import React, { ReactElement } from "react"
import utilStyles from '@/styles/UtilityStyles.module.scss'
import classNames from 'classnames'
import { useDesktop1280UpQuery } from '@/utils/responsive-queries'

interface Props {
  backgroundColor?: string
  color?: string
  fab?: { active: boolean; order: number }
  text?: string
  iconSize?: number
  isExpanded?: boolean
  children: ReactElement
  textDisplayOverride?: string
}

export default function FilledIconButton({
  text,
  color = 'slate',
  backgroundColor = 'bg-gray-light',
  iconSize = 20,
  children,
  isExpanded,
  textDisplayOverride,
  ...rest
}: Props & Omit<IconButtonProps, 'color'>) {
  const fabRems = (rest.fab?.order || 0) * 4
  const isDesktopUser = useDesktopUpQuery()
  const isDesktop1280Up = useDesktop1280UpQuery()
  const textClasses = classNames(
    textDisplayOverride ? utilStyles[textDisplayOverride] : null
  )

  return (
    <div
      className={`h-12 xl:px-4 flex content-center transform-none scale-x-100 ${
        isExpanded //* e.g. search bar
          ? `justify-start absolute left-4 w-[calc(100%-1.75rem)] px-2 z-[1060]`
          : 'justify-center w-12 xl:w-auto'
      } rounded-full ${backgroundColor} ${
        rest.fab?.active && `fixed right-4`
      } ${rest.className}`}
      style={
        rest.fab && {
          bottom: `${fabRems + 1.5}rem`,
          boxShadow: 'rgba(0, 0, 0, 0.1) 0px 3px 15px',
        }
      }
    >
      <IconButton
        disableRipple={true}
        sx={{
          zIndex: (theme) => theme.zIndex.fab + (rest.fab?.active ? -1 : 0),
          width: isExpanded ? '100%' : 'auto',
          justifyContent: isExpanded ? 'flex-start' : 'initial',
          '&:hover': { backgroundColor: 'transparent' },
        }}
        {...rest}
      >
        {React.cloneElement(children, {
          size: iconSize,
          color: twConfig.theme.colors[color],
        })}
        {((isDesktopUser && isDesktop1280Up) || isExpanded) && text && (
          <span
            className={`font-semibold text-${color} text-base ${textClasses}`}
          >
            &nbsp;{text}
          </span>
        )}
      </IconButton>
    </div>
  )
}
