import React, { ChangeEventHandler, useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import classNames from 'classnames'
import styles from '@/styles/components/LocationPicker.module.scss'
import Select from '@/components/common/forms/Select'
import { compareArrayNatural } from '@/utils/helper-functions'
import { setSelectedLocationId } from '@/store/slices/selectedSlice'
import useLocations from '@/hooks/api-hooks/useLocations'
import { UserRoleNames } from '@/consts/UserRolesEnum'
import { useTypedSelector } from '@/store/index'
import { hasMinimumRole } from '@/utils/permissions'
import { useRouter } from 'next/router'
import { setIsUsingSharedSettings } from '@/store/slices/pages/manageSlice'
import { useEffectOnce } from 'react-use'
import textStrings from '@/consts/text-strings'
import useAccount from '@/hooks/api-hooks/useAccount'
import useSelectedWaitlist from '@/hooks/api-selected-hooks/useSelectedWaitlist'
import useMobileCookies from '@/hooks/useMobileCookies'

export default function LocationPicker({
  label = '',
  className = '',
  ...rest
}) {
  const dispatch = useDispatch()
  const { pathname } = useRouter()

  const { locationId, userRole } = useTypedSelector((state) => state.selected)
  const { isUsingSharedSettings } = useTypedSelector(
    (state) => state.pages.manage
  )
  const { isUsingMobileSharedSettings } = useMobileCookies()

  const { account } = useAccount()
  const { locations, isValidating } = useLocations()
  const { selectedWaitlist } = useSelectedWaitlist()

  const showSharedSettings = useMemo(
    () => pathname.startsWith(`/settings/`),
    [pathname]
  )

  const canPickSharedSettings = useMemo(
    () => userRole && hasMinimumRole(UserRoleNames.rm, userRole),
    [userRole]
  )

  useEffectOnce(() => {
    if (!showSharedSettings || !canPickSharedSettings)
      dispatch(setIsUsingSharedSettings(false))
  })

  useEffect(() => {
    if (isUsingMobileSharedSettings) {
      setIsUsingSharedSettings(true)
    }
  }, [isUsingMobileSharedSettings])

  const pickerVal = useMemo(
    () =>
      showSharedSettings && isUsingSharedSettings
        ? textStrings.LOCATION_PICKER_ALL
        : locationId,
    [isUsingSharedSettings, locationId, locations]
  )

  const handleChange: ChangeEventHandler<HTMLSelectElement> = (e) => {
    if (e.target.value === textStrings.LOCATION_PICKER_ALL) {
      dispatch(setIsUsingSharedSettings(true))
    } else {
      dispatch(setIsUsingSharedSettings(false))

      dispatch(setSelectedLocationId(e.target.value))
    }
  }

  const opts = useMemo(() => {
    let options = locations.map((x) => ({
      label: x.name,
      value: x.id,
      disabled: !account?.locations.includes(x.id),
    }))
    options.sort(compareArrayNatural('label'))

    if (showSharedSettings) {
      options.unshift({
        value: textStrings.LOCATION_PICKER_ALL,
        label: textStrings.SHARED_SETTINGS,
        disabled: !canPickSharedSettings,
      })
    }

    return options
  }, [locations, showSharedSettings, canPickSharedSettings, account])

  const hasMultipleOptions = useMemo(() => {
    return opts?.length > 1
  }, [opts])

  return (
    <Select
      className={classNames(styles.locationPickerSelect, className)}
      label={label}
      onChange={handleChange}
      value={pickerVal ? pickerVal : ''}
      options={opts}
      disabled={isValidating || !hasMultipleOptions}
      style={rest.style}
    />
  )
}
