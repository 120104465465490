import useWaitlists from "../api-hooks/useWaitlists";
import { useEffect, useMemo } from "react";
import { useTypedSelector } from "@/store/index";
import { setPublicWaitlistId } from "@/store/slices/selectedSlice";
import { useDispatch } from 'react-redux';
import useSelectedLocation from "./useSelectedLocation";

export default function useSelectedWaitlist() {
  const dispatch = useDispatch()
  const { locationId, selectedLocation } = useSelectedLocation()
  const { hasOnlyOneLocation, isUsingSharedSettings} = useTypedSelector((state) => state.pages.manage)
  const { waitlists, isValidating, isLoading, mutateWaitlists } = useWaitlists()

  const selectedWaitlist = useMemo(() => {
    if (hasOnlyOneLocation || isUsingSharedSettings) {
      return waitlists?.find((wl) => wl.waitlistableType === 'business')
    }
    return waitlists.find((wl) => wl.waitlistableId === locationId)
  }, [locationId, waitlists, hasOnlyOneLocation, isUsingSharedSettings])

  useEffect(() => {
    dispatch(setPublicWaitlistId(selectedWaitlist?.id ?? null))
  }, [selectedWaitlist, selectedLocation])

  return {
    selectedWaitlist,
    isWaitlistsValidating: isValidating,
    isWaitlistsLoading: isLoading,
    mutateWaitlists,
  }
}